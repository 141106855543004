<template>
  <div class="wrapper">
    <div class="btn-group">
      <div></div>
      <div>
        <el-button type="primary" icon="el-icon-plus" size="small" @click="goAdd">添加</el-button>
      </div>
    </div>
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="pageSize" />
  </div>
</template>

<script>
import { getDictListAPI } from './api'

const columns = [
  {
    label: '字典名称',
    width: '240',
    prop: 'dictName',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['dictName']}>
          {row['dictName']}
        </p>
      )
    }
  },
  {
    label: '字典code值',
    width: '240',
    prop: 'dictCode',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['dictCode']}>
          {row['dictCode']}
        </p>
      )
    }
  },
  {
    label: '备注',
    width: '200',
    prop: 'remark',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['remark']}>
          {row['remark']}
        </p>
      )
    }
  },
  {
    label: '操作',
    customRender(h, row) {
      return (
        <div>
          <el-button type="primary" disabled={row['isDefault'] == 1} size="mini" icon="el-icon-edit" onClick={() => this.goEdit(row['id'])}>
            编辑
          </el-button>
          <el-button type="warning" size="mini" icon="el-icon-edit" onClick={() => this.goAddDictValue(row['dictCode'], row['dictName'])}>
            添加字典值
          </el-button>
        </div>
      )
    }
  }
]
export default {
  data() {
    return {
      columns,
      list: [],
      total: 0,
      currentPage: 1,
      pageSize: 20,
    }
  },
  mounted() {
    this.getList()
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },
  methods: {

    async getList() {
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize
      }
      const res = await getDictListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },

    goAdd() {
      this.$router.push({ name: 'dictManageAddOrEdit' })
    },
    goEdit(id) {
      this.$router.push({ name: 'dictManageAddOrEdit', params: { id: id || undefined } })
    },
    goAddDictValue(dictCode, dictName) {
      this.$router.push({ name: 'DictValueManage', query: { dictCode: dictCode || undefined, dictName: dictName || undefined } })
    },
    goRemove(id) {
      console.log(id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-width: 800px;
  /deep/.one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  .el-table__body-wrapper {
    width: 100%;
  }
  .el-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /deep/.el-form-item {
      margin-bottom: 0;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
